@import "~@camtv/front-base-css/main/_variables.scss";
ctv-kyc-dialog,ctv-kyc-admin-dialog,ctv-kyc-dialog-login,ctv-sto-auth-dialog, ctv-sto-test-dialog, ctv-sto-dialog-deploy-data-confirm{

	.hidden { display: none; }

	div.ctv-dialog-shadow.modal {
		flex-direction: column;
	}
/*
	div.ctv-dialog-shadow.modal div.ctv-dialog-wrapper div.ctv-dialog-header .title {
		color: #000000;
		@include media-sm {
			//background-color:#FFCD00;
			//text-shadow: -1px -1px #0000001f;
			background-color: #FFC800;
			color: #222833;
			border-color: #F5C000;
			border:0;
			background: linear-gradient(181deg, #FFCD00, #F5C000);
		}
	}
	ctv-button button,
	.ctv-button {
		// background-color:#FFCD00;
		// color: #000000;
		// border-color:#FFCD00;
		//text-shadow: -1px -1px #0000001f;
		background-color: #FFC800;
		color: #222833;
		border-color: #F5C000;
		background: linear-gradient(181deg, #FFCD00, #F5C000);
		letter-spacing: 0.5px;
	}
*/
	a {
		color: var(--input-blue-label);
	}

	p {
		text-align: left;
		padding: 20px 0;
	}

	.ctv-input-field .options > div label {
		text-align: left;
	}	

	select {
		background: white;
	}

	.ctv-input-field {
		&.error {
			select {
				border-color: transparent;
				-webkit-box-shadow: 0 0 0 2px var(--input-color-error);
				box-shadow: 0 0 0 2px var(--input-color-error);				
			}
		}

		.selfie_placeholder {
			button {
				position: absolute;
				bottom: 30px;
				top: auto;
				left: 50%;
				transform: translateX(-50%);
				svg {
					margin: 0 8px 0 0;
				}

			}
		}
		.selfie_image {
			.delete {
				position: absolute;
    			top: 10px;
    			right: 10px;
				background: rgba(0,0,0,0.65);
				border-radius: 50%;
				height:34px;
				width:34px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					color:white;
				}

			}
		}
	}


	div.separator {
		height: 1px;
		border-bottom:1px solid #ccc;
		margin: 3px 0;
		width: 100%

	}

	.ctv-input-field {
		
		label {
			margin-bottom: 0;				
		}
		
		div.error {
			text-align: left;
		}

		&.error input[type=checkbox] + label::before {
			border: 2px solid red;
		}

	}

	ctv-kyc-dialog-step3 {
		.selfie_placeholder {
			button {
				position: relative;
				top: -80px;
				margin: 0 auto;
			}
		}
	}
	ctv-kyc-dialog-step8 {
		.success{
			margin-top:20px;
			h3 {
				font-size: 20px;
				color: #161616;
				padding: 10px 0;
				margin: 0;
			}
			p {
				font-size: 15px;
				margin: 10px auto;
				padding: 0;
				max-width: 420px;
				line-height: 1.5;
				color: #545454;
			}

			.success-icon {
				margin:20px auto;

			}


		}
	}

	//styles for full screen page 
	.full-page & {
		.ctv-dialog-shadow {
			background-color: transparent;
			position: relative;
			margin:-159px auto 0 auto;

			@include media-sm {
				margin:-130px auto 0 auto;
			}

			div.ctv-dialog-wrapper {
				max-height: none !important;
				min-height: auto !important;
				height: auto !important;
				padding:0 !important;
				margin:0 !important;

				@include media-sm {
					//box-shadow: 0 0 0 1px rgba(0, 0 , 0 ,0.1), 0 -1px 0 0px rgba(255, 255, 255, 1);
					box-shadow: 0 0 0 1px rgba(0, 0 , 0 ,0.1);
					border-radius: 5px;
				}

				div.ctv-dialog-close{
					display:none !important;
				}
				div.ctv-dialog-header {
					height: auto !important;
					min-height: auto;
					border:0;
					.arrow-container,
					.title,
					.help-container {
						display:none;
					}

					.path-container {
						max-width: 650px;
						width: 100%;
						background-color: rgba(255,255,255,0.75);
						border-bottom:0;
						margin:0 auto;
						@include media-sm {
							border:1px solid #fff;
							border-radius: 5px 5px 0 0;
						}

						&::before{
							content: "";
							position: absolute;
							background: #ccc;
							top: 12px;
							left: 25px;
							right: 25px;
							height: 4px;
							z-index: 1;
							top: 32px;
							@include media-sm {
								top: 38px;
								left: 70px;
								right: 70px;
							}
						}

						span.progress {
							content: "";
							position: absolute;
							background: #FF7D00;
							top: 12px;
							left: 50px;
							right: 50px;
							height: 4px;
							z-index: 1;
							/*min-width: 100%;*/
							width: 0;//calc(505px / 2);
						}

						.steps {
							display: flex;
							justify-content: space-between;
							margin: 20px 25px;
							position: relative;
							overflow: auto;
							max-width: 100%;
							
							&::-webkit-scrollbar{
								background-color:transparent;
							}
							&::-webkit-scrollbar-thumb{
								background-color:transparent;
							}
							&::-webkit-scrollbar-thumb:hover{
								background-color:transparent;
							}
							
							@include media-sm {
								overflow: hidden;
							}
				
							
							div.step:first-child {
								position:relative;
								&:before {
									content: "";
									position: absolute;
									background: white;
									width: 32px;
									height: 4px;
									top: 12px;
									left: 0px;
								}
								@media screen and (max-width:450px){
									> div {
										min-width: 88px;
										width: -webkit-fill-available;
									}
								}
							}
							div.step:last-of-type {
								position:relative;
								&:before {
									content: "";
									position: absolute;
									background: white;
									width: 32px;
									height: 4px;
									top: 12px;
									right: 0;
								}
							}
							.step {
								display:flex;
								flex-direction: column;
								z-index: 2;
								justify-content: center;
								align-items: center;
								//min-width: 14%;
								min-width: 88px;
								max-width: 20%;		
													
								&.passed span {
									display: flex;
									padding: 5px;
									background-color: #FF7D00;
									color:white;
									border-radius: 50%;
									width: 25px;
									height: 25px;
									align-items: center;
									justify-content: center;
									line-height: 1;
									font-weight: 400;
									font-size: 15px;
									border:1px solid #FF7D00;
								}
								&.current span {
									border:3px solid #FF7D00;
									background: #fff;
									color: #FF7D00;
									font-size:17px;
									font-weight: 600;
									width: 28px;
									height: 28px;
									+ div {
										margin-top: 8px !important;
									}
								}
								span {
									display: flex;
									padding: 5px;
									background-color: #fff;
									color: #7e7e7e;
									border-radius: 50%;
									width: 25px;
									height: 25px;
									align-items: center;
									justify-content: center;
									line-height: 1;
									font-weight: 400;
									font-size: 15px;
									border:1px solid #ddd;
									z-index: 1;
									+ div {
										font-size: 13px;
										color: #7e7e7e;
										margin-top: 10px;
										font-weight: 400;
										white-space: nowrap;
									}
								}
								&.current span + div {
									font-weight: 500;
									color: #545454;
									margin-top: 10px;
								}
							}
						
						}
					
					}
				}

				div.ctv-dialog-body {
					height: auto !important;
					max-height: none !important;
					//min-height: 255px;
					background-color: #fff;
					padding-top:35px !important;
					padding-bottom:30px;
					border-radius: 5px;
					@include media-sm {
						padding-top:25px !important;
					}

					//STOKYC-12 same height for 3 steps upload images
					ctv-sto-dialog-step-kyc-iddoc-front,
					ctv-sto-dialog-step-kyc-iddoc-back,
					ctv-sto-dialog-step-kyc-utility-bill {
						min-height: 400px;
						display: block;
						@include media-sm {
							min-height: 500px;
						}
					}


					.success-icon {
						margin-bottom: 20px;
					}

					h3.title {
						font-size:18px;
						font-weight: 600;
						color:#161616;
						text-align: left;
						&.center {
							text-align: center;
						}
					}
					.warning-box {
						border:1px solid #FFC893;
						background-color: #FFFBF1;
						padding:10px;
						border-radius: 5px;
						margin-bottom:10px;
						
						h3 {
							color:#FF7D02;
							font-size:23px;
							text-align: center;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-bottom: 5px;
							font-weight: 500;
							svg {
								margin:0 4px;
							}
						}
						.description {    
							text-align: center;
							padding: 0;
							letter-spacing: -0.3px;
						}
					}
					.description {
						font-size:15px;
						color:#545454;
						font-weight: 400;
						padding:20px 0;
						line-height: 1.4;
					}
					.contract-cam-token {
						max-height: 250px;
						overflow-y: scroll;
						text-align: left;
						padding:0;
						margin-top:15px;
					}
					.small {
						font-size:14px;
						color:#545454;
						font-weight: 400;
						text-align: center;
						display: block;
						width: 100%;
						line-height: 1.5;
					}

					.payment-options {
						margin:50px 0 0;
						li {
							
							margin-bottom: 15px;
							&:first-child {
								margin-bottom: 30px;
							}
							&:last-child {
								margin-bottom: 0;
							}

							button {
								width: 100%;
								max-width: 290px;
								justify-content: flex-start;
								padding: 15px 22px;

								span {
									margin-left: 10px;
									font-weight: 600;
								}
							}
						}
					}

					.selfie_placeholder,.selfie_image {
						min-height: 220px;
						@include media-sm {
							min-height: 330px;
						}
					}

					.wiretransfer-data {
						text-align: left;
						margin:0 auto 20px auto;
						background: #f8f8f8;
						border:1px solid #ddd;
						border-radius: 3px;
						padding:10px 20px 20px 20px;
						li {
							text-align: left;
							margin:10px 0;
							font-size:15px;
							color:#545454;
							font-weight: 400;
							
							span {
								color:#161616;
								font-weight: 600;
							}
							&:last-child {
								margin:0;
							}
						}
					}

					.select-payment-crypto {
						background:#f8f8f8;
						padding:0 20px 20px 20px;
						//max-width: 540px;
						margin: 0 auto 20px auto;
						border:1px solid #ddd;
						border-radius: 3px;
						.box-payment-crypto {
							text-align:left;
							@include media-sm {
								display: flex;
								justify-content: space-between;
								> div:first-child {
									max-width: 70%;
								}
								> div:last-child {
									max-width: 30%;
								}
							}

							.cryptotransfer-data {
								
								li {
									margin:10px 0;
									font-size: 15px;
									color:#545454;
									font-weight: 400;
									position: relative;
									padding-right:10px;
									.crypto-address-box {
										display: flex;
									}
									span {
										font-size: 15px;
										color:#161616;
										font-weight: 600;
										&.CRYPTO_ADDRESS{
											display: block;
											word-break: break-all;
										}
									}
									svg {
										display:none;
										width: 20px;
										min-width: 20px;
										height: 20px;
										top: 17px;
										/* position: absolute; */
										right: 4px;
										margin: 0px 6px;
										color: #7b7b7b;
									}
								}
							}
							img {
								border: 1px solid #ddd;
    							margin: 0 auto;
								display: block;
								width: 150px;
								height: 150px;
							}
						}
					}

					.print-button {
						display:flex;
						align-items: center;
						text-decoration: none;
						justify-content: center;
						cursor: pointer;
						span {
							text-decoration: underline;
							margin-left: 5px;
						}
					}

					.document-list {
						max-width: 560px;
						margin: 10px auto 50px;
						li {
							margin:15px 0;
							display: flex;
							align-items: center;
							justify-content: flex-start;
							svg {
								display: block;
								margin-right: 5px;
								min-width: 22px;
							}
							span {
								text-align: left;
								margin-left:10px;
								font-size:14px;
								color:#545454;
							}
						}
					}

					.word-list {
						padding:10px 0px;
						max-width: 440px;
						margin: 0 auto;
						text-align: left;
						display: flex;
						flex-wrap: wrap;
						
						  
						@include media-sm {
							padding:10px 20px;
						}
						span {
							cursor: pointer;
							display: inline-block;
							padding: 10px 8px;
							margin: 4px;
							background: #f8f8f880;
							border-radius: 5px;
							border: 1px solid #cccccc80;
							width: 46%;
							text-align: center;
							position: relative;
							@include media-sm {
								width: 31.3%;
							}

							
						}

						&.order{
							counter-reset: section;
							span {
								//text-align: left;
								cursor: auto;
								&:before {
									counter-increment: section;                   /* Increment the value of section counter by 1 */
									content: counter(section) ". ";
									position: absolute;
									top: -1px;
									//background: #f8f8f880;
									border-radius: 3px;
									font-size: 13px;
									padding: 2px;
									left: 1px;
									color: #7e7e7e;
								}
							}
						}
					}

					.camtoken-qr {
						background: #f6f6f6;
						display: flex;
						flex-direction: column;
						border: 1px solid #ddd;
						padding: 15px;
						border-radius: 5px;
						max-width: 440px;
						margin: 0 auto;
						div {
							word-break: break-all;
						}
						img {
							display: block;
							max-width: 135px;
							margin: 10px auto;
							border: 1px solid #ddd;
						}
					}

					/*custom input padding*/
					input:not([type]),
					input[type="text"],
					input[type="password"],
					input[type="email"],
					input[type="url"],
					input[type="time"],
					input[type="date"],
					input[type="datetime"],
					input[type="amount"],
					input[type="datetime-local"],
					input[type="tel"],
					input[type="number"],
					input[type="search"],
					textarea {
						padding:12px 15px;
					}
					.ctv-input-field.select-field  {
						&:after{
							top: 40px;
						}
						select {
							padding:12px 15px;
						}
					}
				}

				div.ctv-dialog-footer {
					border-top:0;
					> div:first-child {
						display:none;
						
					}
					> div:last-child {
						width: 100%;
						padding:0;
						margin: 0 20px;
						a {
							display: none;
							/*
							position: absolute;
							bottom: -39px;
							left: 50%;
							padding-top:30px;
							padding-bottom:30px;
							transform: translateX(-50%);
							@include media-sm {
								padding-bottom: 0;
								padding-top: 30px;
								min-height: 80px;
								display: flex;
								bottom:-59px;
							}
							*/
						}
						ctv-button{
							width: 100%;
							max-width: 385px;
							margin: 10px auto 30px auto;
							button {
								max-width: 385px;
								width: 100%;
								margin:0 auto;
							}
						}
					}
				}

				ctv-button {
					button {
						padding:12px 15px;
					}
				}
			}
		}
	}

	ctv-sto-dialog-step-kyc-survey {
		.first-row {
			div:nth-child(1) {
				flex: 0 0 20%;
			}
			div:nth-child(2) {
				flex: 0 0 35%;
			}
			div:nth-child(3) {
				flex: 0 0 45%;
			}
		}
		@media screen and (max-width: 550px) {
			.first-row {
				div:nth-child(1) {
					flex: 0 0 30%;
				}
				div:nth-child(2) {
					flex: 0 0 70%;
				}
				div:nth-child(3) {
					flex: 0 0 50%;
				}
			}
		}
		@media screen and (max-width: 450px) {
			.first-row {
				div:nth-child(1) {
					flex: 0 0 40%;
				}
				div:nth-child(2) {
					flex: 0 0 60%;
				}
				div:nth-child(3) {
					flex: 0 0 50%;
				}
			}
		}
	}
	
	// classe for 2 columns in form 
	.form-row {
		&.group-2-cols {
			@include media-sm {
				div:first-child {
					flex: 0 0 30%;
				}
				div:last-child {
					flex: 0 0 70%;
				}
			}
		}
	}

}
ctv-legal-dialog {
	.ctv-dialog-shadow {
		z-index: 61;
	}
}
ctv-sto-dialog-post-footer { 
	display:block;
	font-size:13px;
	line-height:1.4;
	color:var(--color-text-dark);
	width: 100%;
	max-width: 570px;
	margin:0 auto;
	padding: 5px 20px;
	@include media-sm {
		padding: 0;
	}
	a {
		display: flex;
		color: var(--input-blue-label);
		text-decoration: underline;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
	}
}

ctv-toast {
	position: fixed;
	z-index: 51;
	max-width: 200px;
    margin: 0 auto;
	margin-left: -100px;
	> div {
		justify-content: center;
	}
}