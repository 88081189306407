ctv-camera-capturer-dialog {
	
	.ctv-dialog-shadow {
		.ctv-dialog-wrapper {
			padding:0;
			/*
			position: relative;
			height: calc(100vh - 60px);
			*/

			.ctv-dialog-header {
				margin: 0;
				padding: 5px;
			}

			.ctv-dialog-body {
				position:relative;
				//height: calc(100vh - 110px);
				background:black;

				&.tip-show{
					.tip-blue-balloon{
						display: block;
					}
					.btn.shot {
						animation-name: tip-blink;
    					animation-duration: 0.65s;
    					animation-iteration-count: infinite;
					}
				}

				> div {
					position:absolute;
					bottom: 30px;
					width: 100%;
					display: flex;
					flex-direction: row;

					.tip-blue-balloon{
						display:none;
						background: #1079E7;
						width: 100%;
						position: absolute;
						transform: translateX(-50%) translateY(calc(-100% + -18px));
						max-width: 400px;
						color: white;
						padding: 20px;
						border-radius: 5px;
						text-align: left;
						left: 50%;
						&:after{
							background: #1079E7;
							content:"";
							width:20px;
							height:20px;
							transform: rotate(45deg);
							position: absolute;
							bottom:-10px;
							left:calc(50% - 10px);
						}
						.title {
							margin:10px 0;
							position: relative;
							h3 {
								font-size:20px;
								font-weight:500;
							}
							.close {
								text-align: right;
								margin: 0;
								margin-top: -36px;
								position: absolute;
								right: -10px;
								cursor: pointer;
							}
						}
						
						p{
							font-size:15px;
							font-weight: 400;
							margin:10px 0;
						}
						span {
							font-size:13px;
							@media screen and (max-height:470px){
								display:none;
							}
						}
						img {
							max-width: 100%;
							border:1px solid rgba(255,255,255,0.6);
							position: relative;
							@media screen and (max-height:470px){
								display:none;
							}
						}

						.row-accept {
							text-align: center;
							margin:20px 0 10px;
							cursor: pointer;
							&:hover{
								text-decoration: underline;
							}
						}
					}
					
					.btn {	
						background: #000000A0;
						padding: 5px;
						color: white;
						border-radius: 5px;
						font-weight: bold;
						border: 1px solid #cccccc7a;
						width: 48px;
						height: 48px;
						border-radius: 48px;
						margin: auto;
						z-index: 1;
						cursor: pointer;
						display:flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						
						
						&.discard {
							color: red;
						}
						&.keep {
							color: #15A831;
						}

					}
				}
			
				img {
					display:block;
					position: absolute;		
					width: 100%;
					object-fit: cover;
				}
			
				video {
					display:block;
					//position: absolute;		
					width: 100%;
					//height: 100%;
					visibility: hidden;
					min-width: 300px;
					min-height: 200px;
					/*
					max-height:calc(100vh - 110px);
					object-fit: cover;
					*/
					//width:100% !important;
					height:auto;
					width: calc(100vw - 10px);
					max-width: calc(100vw - 10px);
    				max-height: calc(100vh - 34px);
    				object-fit: contain;

				}
			
				canvas {		
					position: absolute;
					top: 0;
					left: 0;
					width: 320;
					height: 240;
				}				
			}

		}
	}

	@keyframes tip-blink{
		0% {
			box-shadow: 0 0 0 2px rgba(16,121,231,0.40), 0 0 0 5px rgba(16,121,231,0.34);
		}
		50% {
			box-shadow: 
			0 0 0 1px rgba(255, 255, 255, 0.10), 
			0 0 0 4px rgba(88, 140, 195, 0.53), 
			0 0 0 8px rgba(56, 115, 166, 0.34), 
			0 0 0 13px rgba(140, 198, 252, 0.28);
		}
		100% {
			box-shadow: 0 0 0 2px rgba(16,121,231,0.40), 0 0 0 5px rgba(16,121,231,0.34);
		}
	}
}